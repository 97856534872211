import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';
import { FieldSpec } from 'components/bel-au-html-table/component';
import { ActorListResponse, MyHttpApi } from 'utils/api';

@autoinject
export class ActorsAdminList {
  private actorList: ActorListResponse[] = [];

  private fields: FieldSpec[] = [
    { header: "actor.username", key: "username", type: "text", },
    { header: "actor.createdBy", key: "createdBy", type: "text" },
    { header: "actor.firstName", key: "firstName", type: "text" },
    { header: "actor.lastName", key: "lastName", type: "text" },
    { header: "actor.customer", key: "clientName", type: "text" },
    { header: "actor.otpInUse", key: "otpInUse", type: "boolean" },
    { header: "actor.canBelAdminShort", key: "canBelAdmin", type: "boolean" },
    { header: "actor.canSuperuserShort", key: "canSuperuser", type: "boolean" },
    { header: "actor.canAdminShort", key: "canAdmin", type: "boolean" },
    { header: "actor.canCreateUsersShort", key: "canCreateUsers", type: "boolean" },
    { header: "actor.canBookkeepingIntegrationShort", key: "canBookkeepingIntegration", type: "boolean" },
    { header: "actor.canGiftCardShort", key: "canGiftCard", type: "boolean" },
    { header: "actor.canIntegrationSettingsShort", key: "canIntegrationSettings", type: "boolean" },
    // { header: "actor.canManifestIntegrationShort", key: "canManifestIntegration", type: "boolean" },
    { header: "actor.canRemoteControlShort", key: "canRemoteControl", type: "boolean" },
    { header: "actor.canPosItemAdminShort", key: "canPosItemAdmin", type: "boolean" },
    { header: "PWD", key: "hasPassword", type: "boolean" },
  ];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    await this.refresh();
  }

  async refresh() {
    this.actorList = await this.api.actorAdminList();
  }

  rowCall(key: string, row?: ActorListResponse) {
    this.router.navigateToRoute("actors/admin/edit", {id: row?.id});
  }
}
