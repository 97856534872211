import { autoinject, computedFrom } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { Router } from 'aurelia-router';
import { ActorAdminUpdateRequest, BusinessGroup, Client, Corporation, IdName, MyHttpApi } from 'utils/api';
import { validEmail } from 'utils/email-utils';
import { Notify } from 'utils/notify';

export interface UIActor extends ActorAdminUpdateRequest {
  otpInUse: boolean;
}


@autoinject
export class ActorsAdminEdit {
  private actor: UIActor = {
    username: "", firstName: "", lastName: "",
    canAdmin: false,
    canSuperuser: false,
    canBelAdmin: false,
    canCreateUsers: false,
    canBookkeepingIntegration: false,
    canGiftCard: false,
    canIntegrationSettings: false,
    canManifestIntegration: false,
    canRemoteControl: false,
    canPosItemAdmin: false,
    clientId: [],
    businessGroupId: [],
    corporationId: [],
    delete: false,
    otpInUse: false,
  };
  private actorClient: IdName[] = [];
  private actorBusinessGroup: IdName[] = [];
  private actorCorporation: IdName[] = [];
  private clientList: Client[] = [];
  private businessGroupList: BusinessGroup[] = [];
  private corporationList: Corporation[] = [];
  private readOnly = false;

  constructor(private notify: Notify, private router: Router, private api: MyHttpApi, private i18n: I18N) {
  }

  async activate(params: { id?: string; }) {
    if (params.id) {
      let id = parseInt(params.id);
      let [actor, actorClient, actorBusinessGroup, actorCorporation] = await Promise.all([
        this.api.actorAdminById({ id }),
        this.api.actorAdminListClient({ id }),
        this.api.actorAdminListBusinessGroup({ id }),
        this.api.actorAdminListCorporation({ id }),
      ]);
      this.actor = {
        ...actor, delete: !!actor.deleteTime, clientId: [], businessGroupId: [], corporationId: []
      };
      this.actorClient = actorClient;
      this.actorBusinessGroup = actorBusinessGroup;
      this.actorCorporation = actorCorporation;
      if ((this.actor.canSuperuser === true && this.api.session?.canSuperuser !== true) ||
        (this.actor.canBelAdmin === true && this.api.session?.canBelAdmin !== true)) {
        this.readOnly = true;
      }
    } else {
      this.actorClient = [];
    }
    [this.clientList, this.businessGroupList, this.corporationList] = await Promise.all([
      this.api.clientList(),
      this.api.businessGroupList(),
      this.api.corporationList(),
    ]);
  }

  validateUsername(username?: string) {
    if (!username || !validEmail(username)) {
      return this.i18n.tr("actor.usernameNotEmail");
    }
    return "";
  }

  async save(deleted: boolean) {
    let usernameError = this.validateUsername(this.actor.username);
    if (!deleted && usernameError) {
      this.notify.info(usernameError);
      return;
    }
    let tmp: ActorAdminUpdateRequest = {
      ...this.actor,
      clientId: this.actorClient.map(x => x.id),
      businessGroupId: this.actorBusinessGroup.map(x => x.id),
      corporationId: this.actorCorporation.map(x => x.id),
      delete: deleted,
    };
    await this.api.actorAdminUpdate(tmp);
    this.router.navigateToRoute("actors/admin/list");
  }

  async delete() {
    if (!this.actor) {
      return;
    }
    await this.save(true);
  }

  async disableOtp() {
    if (!this.actor.id) {
      return;
    }
    let actor = await this.api.actorDisableOtp({ id: this.actor.id });
    this.actor.otpInUse = actor.otpInUse;
    this.notify.info("actor.otpDisabled");
  }

  @computedFrom("clientList", "canSomeAdmin", "actorClient")
  get dynamicClientList() {
    if (this.canSomeAdmin) {
      return [];
    }
    return this.clientList.filter(x => !(this.actorClient.some(ac => ac.id == x.id)));
  }

  @computedFrom("businessGroupList", "actorBusinessGroup", "canSomeAdmin")
  get dynamicBusinessGroupList() {
    if (this.canSomeAdmin) {
      return [];
    }
    return this.businessGroupList.filter(x => !(this.actorBusinessGroup.some(abg => abg.id == x.id)));
  }

  @computedFrom("corporationList", "canSomeAdmin", "actorCorporation")
  get dynamicCorporationList() {
    if (this.canSomeAdmin) {
      return [];
    }
    return this.corporationList.filter(x => !(this.actorCorporation.some(ac => ac.id == x.id)));
  }

  @computedFrom("actor.canAdmin", "actor.canBelAdmin", "actor.canSuperuser")
  get canSomeAdmin() {
    return this.actor.canBelAdmin || this.actor.canSuperuser || this.actor.canAdmin;
  }
}
