import { autoinject } from 'aurelia-framework';
import { ActorListResponse, MyHttpApi } from 'utils/api';
import { Router } from 'aurelia-router';

@autoinject
export class ActorsNormalList {
  private actorList: ActorListResponse[] = [];

  constructor(private api: MyHttpApi, private router: Router) {
  }

  async activate() {
    [this.actorList] = await Promise.all([
      this.api.actorCreateUsersList(),
    ]);
  }

  edit(row?: ActorListResponse) {
    this.router.navigateToRoute("actors/normal/edit", {id: row?.id});
  }
}
